<template>
  <modal
    name="faqEdit"
    height="auto"
    width="900"
    :scrollable="true"
    :clickToClose="false"
  >
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="getTitleModal(item) + descSingular">
            <div class="xrow">
              <div class="col-1-1">
                <div class="form-group">
                  <label for="">Título</label>
                  <textarea class="form-control" v-model="item.titulo" />
                  <div class="message">
                    {{ validation.firstError("item.titulo") }}
                  </div>
                </div>
              </div>
              <div class="col-1-4">
                <div class="form-group">
                  <label for="">
                    {{ getDescricaoAcessoSistema("acessab2b") }}
                  </label>
                  <select class="form-control" v-model="item.acessab2b">
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                  <div class="message">
                    {{ validation.firstError("item.acessab2b") }}
                  </div>
                </div>
              </div>
              <div class="col-1-4">
                <div class="form-group">
                  <label for="">
                    {{ getDescricaoAcessoSistema("acessacallcenter") }}
                  </label>
                  <select class="form-control" v-model="item.acessacallcenter">
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                  <div class="message">
                    {{ validation.firstError("item.acessacallcenter") }}
                  </div>
                </div>
              </div>
              <div class="col-1-4">
                <div class="form-group">
                  <label for="">Tipo</label>
                  <select class="form-control" v-model="item.tipo">
                    <option v-for="tipo in tipos" :value="tipo.tipo">
                      {{ tipo.label }}
                    </option>
                  </select>
                  <div class="message">
                    {{ validation.firstError("item.tipo") }}
                  </div>
                </div>
              </div>
              <div class="col-1-4">
                <div class="form-group">
                  <label for="">
                    {{ getDescricaoAcessoSistema("acessafv") }}
                  </label>
                  <select class="form-control" v-model="item.acessafv">
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                  <div class="message">
                    {{ validation.firstError("item.acessafv") }}
                  </div>
                </div>
              </div>
              <div class="col-1-1">
                <div class="form-group">
                  <label for="">Palavras Chave</label>
                  <VueTagsInput
                    style="max-width: none; width: 100%"
                    v-model="tag"
                    :tags="item.palavras_chave"
                    @tags-changed="(newTags) => (item.palavras_chave = newTags)"
                    :allow-edit-tags="true"
                    placeholder=""
                    :validation="validationTag"
                    class="my-custom-wrapper"
                  ></VueTagsInput>
                  <div class="message">
                    {{ validation.firstError("item.palavras_chave") }}
                  </div>
                </div>
              </div>
              <div class="col-1-1">
                <div class="form-group">
                  <label for="">Conteúdo</label>
                  <vue-editor
                    class="vue2-editor"
                    v-model="item.conteudo"
                    :editor-toolbar="customToolbar"
                  ></vue-editor>
                  <div class="message">
                    {{ validation.firstError("item.conteudo") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="xrow edit-buttons">
              <button
                :class="{
                  'button button-success': true,
                  'button-disabled': validation.hasError(),
                }"
                v-on:click="handleSubmit"
              >
                Salvar
              </button>
              &nbsp;
              <button class="button" v-on:click="close">Fechar</button>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>
<script>
import SimpleVueValidation from "simple-vue-validator";
import CloseModal from "../../../components/CloseModal";
import { VueEditor } from "vue2-editor";
import VueTagsInput from "@johmun/vue-tags-input";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "*obrigatório",
  },
});

export default {
  name: "faqEdit",
  props: [
    "item",
    "update",
    "close",
    "loadTipos",
    "descSingular",
    "getDescricaoAcessoSistema",
    "tipos",
  ],
  components: { CloseModal, VueEditor, VueTagsInput },
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link", "image", "video", "code"],
        ["clean"],
      ],
      tag: "",
      validationTag: [
        {
          classes: "min-length",
          rule: (tag) => tag.text.length < 3,
          disableAdd: true,
        },
      ],
    };
  },
  validators: {
    "item.titulo": (value) => Validator.value(value).required(),
    "item.acessab2b": (value) => Validator.value(value).required(),
    "item.acessacallcenter": (value) => Validator.value(value).required(),
    "item.acessafv": (value) => Validator.value(value).required(),
    "item.palavras_chave": (value) => Validator.value(value).required(),
    "item.conteudo": (value) => Validator.value(value).required(),
    "item.tipo": (value) => Validator.value(value).required(),
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.update();
        }
      });
    },
    getTitleModal(item) {
      if (item.id) {
        return "Editando ";
      }
      return "Criando ";
    },
  }
};
</script>
